<template>
    <div class="fill-height">
        <v-container>
            <auth-header :title="$t('dialogs.forget_password')" to="/login"/>
            <v-form class="auth-form mt-8" @submit.prevent="send" ref="form">
                <v-text-field :label="$t('dialogs.email')"
                            solo
                            :background-color="background"
                            :error-messages="errors.email"
                            v-model="email" :rules="emailRules"/>
                <btn-large class="btn-accent"
                           @click="send"
                           :text="$t('dialogs.send')"/>
            </v-form>
        </v-container>
        <v-snackbar
            top
            v-model="snackbar.show"
            color="orange darken-2 v-snack__fixed"
        >
            {{ snackbar.text }}
        </v-snackbar>
    </div>
</template>

<script>
import AuthHeader from '@/components/app/auth/AuthHeader';
import TextField from '@/components/app/form/TextField';
import {mapActions, mapMutations} from "vuex";
import StaticPageLinks from "@/components/app/common/StaticPageLinks";
import SocialLinks from "@/components/app/auth/SocialLinks";

export default {
    name: "ForgetPassword",
    components: {
        SocialLinks,
        StaticPageLinks,
        AuthHeader,
        TextField
    },
    data: () => ({
        email: '',
        errors: {},
        snackbar: {
            show: false,
            text: '',
        },
        emailRules: [
            (v) => !!v || 'The email field is required',
            v => /.+@.+/.test(v) || "E-mail must be valid"
        ],
        background: 'rgba(237,237,237, .1)'
    }),
    methods: {
        send() {
            if (!this.$refs.form.validate()) return;
            this.sendForgotPassword({email: this.email}).then((res) => {
                this.snackbar.text = res.success
                this.snackbar.show = true
            }, (e) => this.errors = e.response.data.errors)
        },
        ...mapActions([
            'sendForgotPassword',
        ]),
        ...mapMutations([
            'showSnackBar',
        ]),
    }
}
</script>
